import React, { useState } from "react";
import "./recrutement.scss";
import { send } from "emailjs-com";
import { Slide } from "react-awesome-reveal";


function Recrutement() {
  const [toSend, setToSend] = useState({
    nom: "",
    prenom: "",
    telephone: "",
    message: "",
    email: "",
  });

  const onSubmit = (e) => {
    e.preventDefault();
    send("service_60lz1m8", "template_kue15fa", toSend, "vPevnm3T39p2JnFMZ")
      .then((response) => {
        console.log("SUCCESS!", response.status, response.text);
        setToSend({
          from_name: '',
          to_name: '',
          telephone: '',
          adresse: '',
          cp: '',
          ville: '',
          message: '',
          reply_to: '',
        })
        alert("Votre demande est bien envoyée !");
        
      })
      .catch((err) => {
        console.log("FAILED...", err);
      });
  };

  const handleChange = (e) => {
    setToSend({ ...toSend, [e.target.name]: e.target.value });
  };

  return (
    <div className="recrutement">
      <div className="recrutement__hero">
        <div className='recrutement__hero__img'>
            <div className='recrutement__hero__img__container'>
            <h1>Rejoignez-nous !</h1>
            

            </div>

        </div>
        <section className="recrutement__hero__top">
            <Slide direction={"left"}>
            <div className="recrutement__hero__top__text">
            L’ aventure continue … avec vous !<br></br><br></br>

Vous souhaitez rejoindre l’équipe ?<br></br><br></br>

Vous êtes débutant ou confirmé, mais passionné par le métier ?<br></br><br></br>

Devenir mandataire immobilier en profitant d’une rémunération attractive et en travaillant uniquement pour vous n’aura jamais été aussi simple !<br></br><br></br>

Vous pouvez trouver des informations supplémentaires ici pour découvrir les conditions avantageuses qui s'offrent à vous si vous souhaitez nous rejoindre : <br></br><br></br><a href="annexe2.pdf" download="halle-immo.pdf"> En savoir plus</a>
            </div>
            </Slide>
            <Slide direction={"right"}>
            <form onSubmit={onSubmit}>
            <div>
                <input
                type="text"
                name="nom"
                placeholder="Votre nom"
                value={toSend.nom}
                onChange={handleChange}
                />
                <input
                type="text"
                name="prenom"
                placeholder="Votre prenom"
                value={toSend.prenom}
                onChange={handleChange}
                />
            </div>
            <div>
                <input
                type="text"
                name="email"
                placeholder="Votre email"
                value={toSend.email}
                onChange={handleChange}
                />
                <input
                type="text"
                name="telephone"
                placeholder="Votre n° de téléphone"
                value={toSend.telephone}
                onChange={handleChange}
                />
            </div>
            <textarea
                type="text"
                name="message"
                placeholder="Votre message"
                rows="5"
                cols="33"
                value={toSend.message}
                onChange={handleChange}
            />

            <div
                class="g-recaptcha"
                data-sitekey="6LdBNd0kAAAAANpb91wGwEfrSvHmBN4kXszhqNzD"
            ></div>
            <br />
            <button type="submit">Envoyer</button>
            </form>
            </Slide>
        </section>
    
      </div>
    </div>
  );
}

export default Recrutement;
