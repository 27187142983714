import React from "react";
import "./contact.scss";
import { useState } from 'react';
import { send } from 'emailjs-com';


function Contact() {
  const [toSend, setToSend] = useState({
    nom: "",
    prenom: "",
    telephone: "",
    message: "",
    email: "",
  });

  const onSubmit = (e) => {
    e.preventDefault();
    send("service_60lz1m8", "template_kue15fa", toSend, "vPevnm3T39p2JnFMZ")
      .then((response) => {
        console.log("SUCCESS!", response.status, response.text);
        setToSend({
          nom: "",
          prenom: "",
          telephone: "",
          message: "",
          email: "",
        })
        alert("Votre message est bien envoyé !");
      })
      .catch((err) => {
        console.log("FAILED...", err);
      });
  };

  const handleChange = (e) => {
    setToSend({ ...toSend, [e.target.name]: e.target.value });
  };

 

  return (
    <div className="contact">
        <div className="contact__hero"> 
            <img src="contact.png" alt="Contactez-nous !" />

        
            
        
        </div>
        <form onSubmit={onSubmit}>
                <h1>Contactez-nous</h1>

                <div>
               

                <input
                type="text"
                name="nom"
                placeholder="Votre nom"
                value={toSend.nom}
                onChange={handleChange}
                /> 
                 <input
                type="text"
                name="prenom"
                placeholder="Votre prenom"
                value={toSend.prenom}
                onChange={handleChange}
                /> 
                </div>
                <div>
                <input
                type="text"
                name="email"
                placeholder="Votre email"
                value={toSend.email}
                onChange={handleChange}
                />
                <input
                type="text"
                name="telephone"
                placeholder="Votre n° de téléphone"
                value={toSend.telephone}
                onChange={handleChange}
                />
                </div>
                <textarea
                type="text"
                name="message"
                placeholder="Votre message"
                rows="5" cols="33"
                value={toSend.message}
                onChange={handleChange}
                />
                
                <div
                class="g-recaptcha"
                data-sitekey="6LdBNd0kAAAAANpb91wGwEfrSvHmBN4kXszhqNzD"
                ></div>
                <br />
                <button type="submit">Envoyer</button>
        </form>
    </div>
  );
}

export default Contact;
