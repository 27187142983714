import React from "react";
import "./biens.scss";
import { useEffect, useState } from "react";
import CardHome from "../elements/CardHome";
import { Link } from "react-router-dom";
import Search from "../search/Search";
import { url } from "../../Data.jsx";
import { Fade } from "react-awesome-reveal";
import Loading from "../elements/Loading";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";

function Biens() {
  const [allBiens, setAllBiens] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [token, setToken] = useState("");
  const [video, setVideo] = useState();

  function getToken() {
    let username = process.env.REACT_APP_USERNAME;
    let password = process.env.REACT_APP_PASSWORD;
    let auth = btoa(`${username}:${password}`);

    fetch(url + "/client/token/site?site_id=104826&manufacturer_id=", {
      method: "POST",
      headers: {
        Authorization: `Basic ${auth}`,
      },
    })
      .then(function (response) {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then(function (data) {
        localStorage.setItem("access", data.access_token);
        setToken(data.access_token);
        console.log("POST basic auth " + data);
        console.table(data);
      })
      .catch(function (error) {
        console.warn(error);
      });
  }

  function getAllBiens() {
    fetch(
      url +
        "/site/products/search?fetch=products_photos,descriptions,criteres_text,criteres_fulltext,criteres_number&sort_type=date&sort_order=desc",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: {
          manufacturer_id: process.env.REACT_APP_MANUFACTURER,
        },
      }
    )
      .then(function (response) {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then(function (data) {
        setAllBiens(data);
        setIsLoading(false);
        console.table(data);
      })
      .catch(function (error) {
        console.warn(error);
      });
  }

  function getVideo() {
    fetch(`https://85m.fr/halle-immo/wp-json/wp/v2/posts`, {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
      })
      .then((dataResponse) => {
        setVideo(dataResponse);
        console.log("video " + dataResponse);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  useEffect(() => {
    getToken();
  }, []);

  useEffect(() => {
    if (token) {
      getAllBiens();
      getVideo();
    }
  }, [token]);

  return (
    <div className="biens">
      <div className="biens__hero">
        <div className="biens__hero__container">
          <Search />
        </div>
      </div>

      <section className="biens__cards">
        {isLoading ? (
          <Loading />
        ) : (
          <Fade>
            <div className="biens__cards__box">
              {allBiens?.map((elt, index) =>
                elt.criteres_text
                  .filter((e) => e.critere_id === 121)
                  .map((f) => {
                    return (
                      f.critere_value === "EnCours" && (
                        <article>
                          <div className="biens__cards__box__video">
                            {video
                              ? video
                                  ?.filter(
                                    (e) => `${e.acf.id_du_bien}` === `${elt.id}`
                                  )
                                  .map((f) =>
                                    f.acf.id_du_bien === `${elt.id}` ? (
                                      <div className="biens__cards__box__video__div">
                                        <PlayCircleFilledIcon size="small" />{" "}
                                        <a
                                          href={f.acf.ajouter_la_video.url}
                                          target="_blank"
                                        >
                                          <span>&nbsp; Visite en Vidéo</span>
                                        </a>
                                      </div>
                                    ) : (
                                      " "
                                    )
                                  )
                              : ""}
                          </div>

                          <Link to={`/${elt.id}`}>
                            <CardHome
                              key={index}
                              image={elt.products_photos[0].chemin}
                              titre={elt.descriptions[0].title}
                              ville={elt.criteres_text
                                .filter((e) => e.critere_id === 54)
                                .map((f) => {
                                  return f.critere_value;
                                })}
                              rooms={elt.criteres_number
                                .filter((e) => e.critere_id === 33)
                                .map((f) => {
                                  return f.critere_value;
                                })}
                              bedrooms={elt.criteres_number
                                .filter((e) => e.critere_id === 38)
                                .map((f) => {
                                  return f.critere_value;
                                })}
                              size={elt.criteres_number
                                .filter((e) => e.critere_id === 34)
                                .map((f) => {
                                  return f.critere_value;
                                })}
                              price={elt.criteres_number
                                .filter((e) => e.critere_id === 30)
                                .map((f) => {
                                  return f.critere_value;
                                })}
                            />
                          </Link>
                        </article>
                      )
                    );
                  })
              )}
            </div>
          </Fade>
        )}
      </section>
    </div>
  );
}

export default Biens;
