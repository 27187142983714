import './App.scss';
import { AllRoutes } from './AllRoutes';
import Navbar from './components/navbar/Navbar';
import Footer from './components/footer/Footer';
import CookieConsent from 'react-cookie-consent';
import { Link } from 'react-router-dom';

function App() {
  return (
    <div className="App">
      <Navbar />
      <AllRoutes />
      <Footer />
      <CookieConsent 
        
        debug={true}
        style={{fontFamily:'raleway', color: 'white'}}
        buttonStyle={{backgroundColor:'#ee7933', borderRadius:"5px", color:'white'}}
        buttonText="J'ai compris"
        declineButtonText="X"
        expires={60}
        enableDeclineButton
        flipButtons>
          Ce site utilise des cookies. Lire les <Link to="mentions-legales" style={{color: 'white'}}> mentions légales</Link> pour plus d'nformations
      </CookieConsent>
    </div>
  );
}

export default App;
