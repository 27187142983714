import React from 'react';
import "./honoraires.scss";

function Honoraires() {
  return (
    <div className='honoraires'>
        <h1>Honoraires</h1>
        <img src="honoraires.jpeg" alt="Honoraires" />
    </div>
  )
}

export default Honoraires