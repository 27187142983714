import React from "react";
import { Slide } from "react-awesome-reveal";
import "./about.scss";

function About() {
  return (
    <div className="about">
      <img src="HI-Logo-Orange.png" alt="Halle Immo" />

      <p className="about__text">
        <br></br>
        Halle-Immo, avant tout, c’est l’histoire de trois hommes. Une amitié.
        Une envie de vivre notre passion commune et de vous accompagner dans vos
        différents projets immobiliers.<br></br>
        <br></br>
        Nicolas, Vincent et Xavier, en tant que professionnels efficaces et
        impliqués, ont souhaité créer leur propre agence. Dans un monde de
        l’immobilier en constante évolution, nous proposons un accompagnement
        sur mesure ainsi que de nombreux services pour vous assurer conseils et
        sérénité à chaque étape de votre projet.<br></br>
        <br></br>A taille humaine, notre agence immobilière, située dans la
        charmante commune de Crécy la Chapelle est spécialisée dans tous types
        de transactions sur l’Est Parisien. <br></br>
        <br></br>
        Notre zone géographique n’a pas de limite car nous savons que les
        projets les plus fous peuvent se faire ici, mais aussi ailleurs.
        <br></br>
        <br></br>
        L’agence Halle-Immo ne dépend pas d’un réseau ni d’une franchise, afin
        d’adapter notre service au plus près de nos clients. <br></br>
        <br></br>
        Fort de notre proximité et de notre dimension humaine, nous avons
        forcément une solution pour vous !
      </p>

      <div className="about__pics">
        <div className="about__pics__div">
          <img src="nicolas.webp" alt="Nicolas" />
          <div>Nicolas Lebouc</div>
        </div>
        <div className="about__pics__div">
          <img src="xavier.webp" alt="Xavier" />
          <div>Xavier Laporte</div>
        </div>
        <div className="about__pics__div">
          <img src="vincent.png" alt="Vincent" />
          <div>Vincent Clergue</div>
        </div>
      </div>
    </div>
  );
}

export default About;
