import React, {useState, useEffect} from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';
import {nav} from "../../Data.jsx";
import { Link } from 'react-router-dom';
import {theme} from '../../styles/Theme';
import {ThemeProvider } from '@mui/material/styles';
import "./slideDrawer.scss";

function SlideDrawer() {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <ThemeProvider theme={theme}>
      <Box 
        sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250, fontFamily: 'Raleway' }}
        role="presentation"
        onClick={toggleDrawer(anchor, false)}
        onKeyDown={toggleDrawer(anchor, false)}
        
      >
        <List>
          {nav.map((text, index) => (
            <ListItem key={index} disablePadding>
              <ListItemButton>
                <Link to={text.path} >
                  <ListItemText primary={text.text} sx={{color: "black", fontFamily: "Raleway"}}/>
                </Link>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Divider sx={{marginBottom:"20px"}}/>
          
                  <Link to="/estimation" >
                    <Button variant="contained" color={theme.main} size="large" sx={{color: "white", marginLeft:"20px"}}>Estimation</Button>
                  </Link>
          
      </Box>
    </ThemeProvider>
  );

  return (
    <div>
      
        <React.Fragment >
        <ThemeProvider theme={theme}>
          <Button onClick={toggleDrawer('right', true)}>
            
              <MenuIcon fontSize='large' color={theme.main}/>
            
          </Button>
          </ThemeProvider>
          <Drawer
            anchor='right'
            open={state['right']}
            onClose={toggleDrawer('right', false)}
            sx={{color:'black'}}
          >
            {list('right')}
          </Drawer>
        </React.Fragment>
     
    </div>
  );
}

export default SlideDrawer