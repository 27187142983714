import React from 'react';
import "./puce.scss";

function Puce({critere}) {
  return (
    <div className='puce'>
        {critere}
    </div>
  )
}

export default Puce